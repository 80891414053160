<template>
  <h1 style="text-align: center">Coming soon to Studiospace</h1>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Tasks'
})
</script>
